<template>
  <LayoutSuperadmin>
    <template v-slot:title>Favorites</template>

    <div class="members h-100">
      <div class="members-list m-auto p-md-3 pl-md-5 pr-md-5">
        <AppLoading v-if="!favorites"></AppLoading>

        <div v-else class="row mt-5 p-md-3">
          <template v-if="favorites.length > 0">
            <div
              v-for="(favorite, index) in favorites"
              :key="`product-item-${index}`"
              class="col-6 col-md-3 mb-5 mb-md-4"
            >
              <ProductListCard
                v-if="favorite.product"
                :name="favorite.product.name"
                :price="favorite.product | displayPrice"
                :thumbnail="favorite.product | displayThumbnail"
                :stocks="getProductStocks(favorite.product)"
                :favorite="true"
                :product_id="favorite.product.id"
              ></ProductListCard>
            </div>
          </template>
          <h2 v-else class="ml-5 w-100 text-center text-uppercase">
            No Favorite Product Added.
          </h2>
        </div>
      </div>
    </div>
  </LayoutSuperadmin>
</template>

<script>
import LayoutSuperadmin from '@/components/Partial/Layouts/LayoutSuperadmin/LayoutSuperadmin.vue';
import ProductListCard from '@/components/Partial/Products/ProductListCard.vue';
import AppLoading from '@/shared/elements/AppLoading.vue';
import Favorites from '@/shared/api/Favorites';
import Vue from 'vue';

export default {
  name: 'FavoriteList',
  components: {
    AppLoading,
    LayoutSuperadmin,
    ProductListCard,
  },

  data() {
    return {
      loading: false,
      favorites: [],
    };
  },

  filters: {
    displayPrice(product) {
      const price_start = Vue.options.filters.toMoney(product.price_start);
      const price_end = Vue.options.filters.toMoney(product.price_end);
      const price_fixed = Vue.options.filters.toMoney(product.price_fixed);

      if (price_start > 0 && price_start !== price_end) {
        return `$${price_start} - $${price_end}`;
      } else if (
        price_fixed === 0 ||
        price_fixed === '' ||
        price_fixed == null
      ) {
        return `$${price_start}`;
      } else {
        return `$${price_fixed}`;
      }
    },
    displayThumbnail(product) {
      const files = product.files;
      const primaryImageIndex = files.findIndex(
        (value) => value.type === 'primary_image'
      );
      if (files.length === 0) {
        return null;
      } else if (primaryImageIndex === -1) {
        return files[0].filename;
      } else {
        return files[primaryImageIndex].filename;
      }
    },
  },

  methods: {
    async getFavorites() {
      const favorites = (await Favorites.getFavorites()).data;

      this.favorites = favorites.filter((f) => f.product !== null);
    },

    getProductStocks(item) {
      if (item.variants && item.variants.length > 0) {
        return item.variants.reduce((sum, variant) => sum + variant.stocks, 0);
      }

      return parseInt(item.stocks);
    },
  },

  mounted() {
    this.getFavorites();
  },
};
</script>
